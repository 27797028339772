/* eslint-disable no-return-assign */
import store from 'redux/store';
import CONFIG from 'config';
import history from 'redux/history';
import { actions as searchActions, selectors as searchSelectors } from 'redux/api/search/search';
import { selectors as casesv2SearchSelectors } from 'redux/api/casesv2Search/casesv2Search';
import { selectors as labSelectors } from 'redux/api/lab/lab';
import { selectors as peopleSelectors } from 'redux/api/peopleResults/peopleResults';
import { selectors as knowledgeSelectors } from 'redux/api/knowledge/knowledge';
import { actions as typeAheadActions } from 'redux/components/type-ahead/type-ahead';
import { selectors as bcgInternalSelectors } from 'redux/api/bcgInternal/bcgInternal';
import { selectors as expertSelectors } from 'redux/api/expertSearch/expertSearch';
import { selectors as paTopicsSelectors } from 'redux/api/paTopics/paTopics';
import { checkForColonDecoding } from 'utils/string-mapper/string-mapper';
import { getNewSearchTerm, replaceSpecialRegExCharacters } from 'utils/queryintent/queryintent';
import { convertFilterObjectToArray, fetchFilterValue, getFilterInConfig, splitDateRangeString } from 'utils/filters/filters';
import { actions as serpActions, selectors as serpTabsSelectors } from 'redux/components/serp/serp';
import tabs from 'containers/SERP/tabs';

export function spreadCompoundFilters(CONFIG_SUBSECTION, _appliedFilters, _appliedQueryParams = {}) {
  const newAppliedFilters = {};
  Object.keys(_appliedFilters).forEach((key) => {
    const filterConfig = getFilterInConfig(CONFIG_SUBSECTION, 'FILTER_BAR', key);
    switch (filterConfig.type) {
      case 'daterange':
        const { beginDateRaw, endDateRaw } = splitDateRangeString(fetchFilterValue(_appliedFilters, _appliedQueryParams, key));
        // the following could hurt us later: using a find based of include could cause this part of code to error if the strings in the include are not in the name of the fields
        const startDateParam = filterConfig.RELATED_PARAMS.find((param) => param.includes('startDate'));
        const endDateParam = filterConfig.RELATED_PARAMS.find((param) => param.includes('endDate'));
        // end pain
        newAppliedFilters[startDateParam] = beginDateRaw;
        newAppliedFilters[endDateParam] = endDateRaw;
        // dont include original for now
        // newAppliedFilters[key] = _appliedFilters[key];
        break;
      default:
        newAppliedFilters[key] = _appliedFilters[key];
    }
  });
  return newAppliedFilters;
}

export function getAppliedFilterColumnsAndValues(CONFIG_SUBSECTION, _appliedFilters, _appliedQueryParams = {}) {
  const newAppliedFilterColumns = [];
  const newAppliedFilterValues = [];

  Object.keys(_appliedFilters).forEach((key) => {
    let name, value;

    if (Array.isArray(_appliedFilters[key])) {
      if (_appliedFilters[key].length > 0) {
        name = ((_appliedFilters[key])[0].SOURCENAME || (_appliedFilters[key])[0].COLUMNNAME) || key;
        value = _appliedFilters[key].map((af) => af.QUERYVALUEFIELD ? af[af.QUERYVALUEFIELD] : af.path);
      } else {
        name = key;
        value = [];
      }
    } else {
      name = key;
      value = [_appliedFilters[key]];
    }

    const filterConfig = getFilterInConfig(CONFIG_SUBSECTION, 'FILTER_BAR', key);
    if (filterConfig.type === 'daterange') {
      const { beginDateRaw, endDateRaw } = splitDateRangeString(fetchFilterValue(_appliedFilters, _appliedQueryParams, key));
      value = [beginDateRaw, endDateRaw];
    }

    newAppliedFilterColumns.push(name);
    newAppliedFilterValues.push(value);
  });

  return newAppliedFilterColumns.map((name, index, collection) => {
    return {
      name: name,
      value: newAppliedFilterValues[index]
    };
  });
}

export const getTabIndex = (label) => {
  const tabArray = tabs.map((tab) => tab.typeAhead.LABEL);

  return tabArray.indexOf(label) > -1 ? tabArray.indexOf(label) : 0;
};

export const getTabInfo = (label, selectedSerpTabIndex) => {
  const tabArray = tabs.map((tab) => tab.typeAhead.LABEL);

  const index = tabArray.indexOf(label) > -1 ? tabArray.indexOf(label) : selectedSerpTabIndex;
  return {
    index,
    ...tabs[index]
  };
};

export function getAppliedFiltersArray(targetTab) {
  if (targetTab === CONFIG.SEARCH.SCOPES.PEOPLE) {
    return convertFilterObjectToArray(peopleSelectors.getAppliedFilters(store.getState()))
      .filter((f) => !f.HIDE_PILL);
  }

  if (targetTab === CONFIG.SEARCH.SCOPES.CASE) {
    return convertFilterObjectToArray(casesv2SearchSelectors.getAppliedFilters(store.getState()))
      .filter((f) => !f.HIDE_PILL);
  }

  if (targetTab === CONFIG.SEARCH.SCOPES.LAB) {
    return convertFilterObjectToArray(labSelectors.getAppliedFilters(store.getState()))
      .filter((f) => !f.HIDE_PILL);
  }

  if (targetTab === CONFIG.SEARCH.SCOPES.KNOWLEDGE) {
    return convertFilterObjectToArray(knowledgeSelectors.getAppliedFilters(store.getState()))
      .filter((f) => !f.HIDE_PILL);
  }
  if (targetTab === CONFIG.SEARCH.SCOPES.EXPERTS) {
    return convertFilterObjectToArray(expertSelectors.getAppliedFilters(store.getState()))
      .filter((f) => !f.HIDE_PILL);
  }
  if (targetTab === CONFIG.SEARCH.SCOPES.BCG_INTERNAL) {
    return convertFilterObjectToArray(bcgInternalSelectors.getAppliedFilters(store.getState()))
      .filter((f) => !f.HIDE_PILL);
  }

  return [];
}

export function goToPeopleSERP(brand, tabSwitch = false) {
  let { query } = peopleSelectors.getQuery(store.getState());
  const lastSearch = peopleSelectors.getLastSearch(store.getState());
  const scope = searchSelectors.getSearchScope(store.getState());
  const targetTab = CONFIG.SEARCH.SCOPES.PEOPLE;
  const appliedFilters = getAppliedFiltersArray(targetTab);
  query = tabSwitch ? lastSearch.query.query : query;
  const queryParams = encodeURIComponent(query !== undefined ? query : '');
  let typeAheadSearchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}`;

  if (query && appliedFilters.length === 0) {
    brand.DEFAULT_SEARCH_PARAMS.PEOPLE.forEach((param) => {
      typeAheadSearchUrl += `&${param.PARAM}=${param.VALUE}`;
    });
  }
  appliedFilters.map((f) => {
    if (f.QUERY_PARAM) {
      typeAheadSearchUrl += `&${f.QUERY_PARAM}=${f.text}`;
    }
    return typeAheadSearchUrl;
  });
  if (tabSwitch) {
    searchActions.setSearchScope(CONFIG.SEARCH.SCOPES.PEOPLE)(store.dispatch);
  }
  history.push({ search: typeAheadSearchUrl, pathname: `${CONFIG.UI_URL.PEOPLE}`, state: { performPeopleSearch: true, doNotReload: tabSwitch } });
  store.dispatch(serpActions.setSelectedIndex(getTabIndex(CONFIG.TYPEAHEAD.PEOPLE.LABEL)));
}

export function goToCaseSERP(brand, tabSwitch = false, autoCorrectMode = true) {
  let { query } = casesv2SearchSelectors.getQuery(store.getState());
  const lastSearch = casesv2SearchSelectors.getLastSearch(store.getState());
  const scope = searchSelectors.getSearchScope(store.getState());
  const targetTab = CONFIG.SEARCH.SCOPES.CASE;
  const appliedFilters = getAppliedFiltersArray(targetTab);
  query = tabSwitch ? lastSearch.query.query : query;
  const queryParams = `${encodeURIComponent(query !== undefined ? query : '')}&${CONFIG.CASE_QUERY_PARAMS.SORTING_ORDER}=${CONFIG.DEFAULT_SORTERS.CASE}`;
  let searchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}&${CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT}=${autoCorrectMode}`;

  if (query && appliedFilters.length === 0) {
    brand.DEFAULT_SEARCH_PARAMS.CASE.forEach((param) => {
      searchUrl += `&${param.PARAM}=${param.VALUE}`;
    });
  }
  appliedFilters.map((f) => {
    if (f.QUERY_PARAM) {
      searchUrl += `&${f.QUERY_PARAM}=${encodeURIComponent(f.path)}`;
    }
    return searchUrl;
  });
  if (tabSwitch) {
    searchActions.setSearchScope(CONFIG.SEARCH.SCOPES.CASE)(store.dispatch);
  }
  history.push({ search: searchUrl, pathname: `${CONFIG.UI_URL.CASE}`, state: { performCaseSearch: true, doNotReload: tabSwitch } });
  store.dispatch(serpActions.setSelectedIndex(getTabIndex(CONFIG.TYPEAHEAD.CASE.LABEL)));
}

export function goToKnowledgeSERP(typeAheadSuggestion, brand, tabSwitch = false, autoCorrectMode = true) {
  let { query } = knowledgeSelectors.getQuery(store.getState());
  const queryValue = checkForColonDecoding(query);
  const lastSearch = knowledgeSelectors.getLastSearch(store.getState());
  const scope = searchSelectors.getSearchScope(store.getState());
  const isAiView = serpTabsSelectors.getIsAiView(store.getState());
  const targetTab = CONFIG.SEARCH.SCOPES.KNOWLEDGE;
  const appliedFilters = getAppliedFiltersArray(targetTab);
  query = tabSwitch ? lastSearch.query.query : query;

  let searchValue;
  if (CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.VALUE === typeAheadSuggestion.category) {
    searchValue = `filename::"${queryValue}"`;
  } else {
    searchValue = queryValue;
  }

  const queryIntents = knowledgeSelectors.getQueryIntents(store.getState());
  if (!isAiView && queryIntents?.length > 0) {
    searchValue = getNewSearchTerm(queryIntents, searchValue);
    query = getNewSearchTerm(queryIntents, query);
    typeAheadActions.changeSearchTerm(query)(store.dispatch);
  }

  const queryParams = `${encodeURIComponent(searchValue !== undefined ? searchValue : '')}&${CONFIG.QUERY_PARAMS.SORTING_ORDER}=${CONFIG.DEFAULT_SORTERS.KNOWLEDGE}`;
  let typeAheadSearchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}&${CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT}=${autoCorrectMode}`;

  if (query && appliedFilters.length === 0) {
    brand.DEFAULT_SEARCH_PARAMS.KNOWLEDGE.forEach((param) => {
      typeAheadSearchUrl += `&${param.PARAM}=${param.VALUE}`;
    });
    if (typeAheadSuggestion.category === CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.VALUE) {
      typeAheadSearchUrl = `${typeAheadSearchUrl}&${CONFIG.QUERY_PARAMS.AUTHOR}=${encodeURIComponent(searchValue)}`;
    }
  }
  appliedFilters.map((f) => {
    if (f.QUERY_PARAM) {
      typeAheadSearchUrl += `&${f.QUERY_PARAM}=${encodeURIComponent(f.path)}`;
    }
    return typeAheadSearchUrl;
  });
  if (tabSwitch) {
    searchActions.setSearchScope(CONFIG.SEARCH.SCOPES.KNOWLEDGE)(store.dispatch);
  }
  history.push({ search: typeAheadSearchUrl, pathname: `${CONFIG.UI_URL.KNOWLEDGE}`, state: { performKnowledgeSearch: true, doNotReload: tabSwitch } });
  store.dispatch(serpActions.setSelectedIndex(getTabIndex(CONFIG.TYPEAHEAD.KNOWLEDGE.LABEL)));
}
export function goToExpertSERP(typeAheadSuggestion, brand, tabSwitch = false, autoCorrectMode = true) {
  let { query } = expertSelectors.getQuery(store.getState());
  const lastSearch = expertSelectors.getLastSearch(store.getState());
  const scope = searchSelectors.getSearchScope(store.getState());
  const targetTab = CONFIG.SEARCH.SCOPES.EXPERTS;
  const appliedFilters = getAppliedFiltersArray(targetTab);
  query = tabSwitch ? lastSearch.query.query : query;

  let searchValue;
  if (CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.VALUE === typeAheadSuggestion.category) {
    searchValue = `filename::"${query}"`;
  } else {
    searchValue = query;
  }
  const queryParams = `${encodeURIComponent(searchValue !== undefined ? searchValue : '')}&${CONFIG.QUERY_PARAMS.SORTING_ORDER}=${CONFIG.DEFAULT_SORTERS.EXPERT}`;
  let typeAheadSearchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}&${CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT}=${autoCorrectMode}`;

  if (query && appliedFilters.length === 0) {
    brand.DEFAULT_SEARCH_PARAMS.EXPERTS.forEach((param) => {
      typeAheadSearchUrl += `&${param.PARAM}=${param.VALUE}`;
    });
    if (typeAheadSuggestion.category === CONFIG.TYPEAHEAD.LAB.CATEGORIES.AUTHOR.VALUE) {
      typeAheadSearchUrl = `${typeAheadSearchUrl}&${CONFIG.QUERY_PARAMS.AUTHOR}=${encodeURIComponent(searchValue)}`;
    }
  }
  appliedFilters.map((f) => {
    if (f.QUERY_PARAM) {
      typeAheadSearchUrl += `&${f.QUERY_PARAM}=${encodeURIComponent(f.path)}`;
    }
    return typeAheadSearchUrl;
  });
  if (tabSwitch) {
    searchActions.setSearchScope(CONFIG.SEARCH.SCOPES.EXPERTS)(store.dispatch);
  }
  history.push({ search: typeAheadSearchUrl, pathname: `${CONFIG.UI_URL.EXPERTS}`, state: { performExpertSearch: true, doNotReload: tabSwitch } });
  store.dispatch(serpActions.setSelectedIndex(getTabIndex(CONFIG.TYPEAHEAD.EXPERTS.LABEL)));
}
export function goToBcgInternalSERP(typeAheadSuggestion, brand, tabSwitch = false, autoCorrectMode = true) {
  let { query } = bcgInternalSelectors.getQuery(store.getState());
  const lastSearch = bcgInternalSelectors.getLastSearch(store.getState());
  const targetTab = CONFIG.SEARCH.SCOPES.BCG_INTERNAL;
  const appliedFilters = getAppliedFiltersArray(targetTab);
  query = tabSwitch ? lastSearch.query.query : query;

  let searchValue;
  if (CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.VALUE === typeAheadSuggestion.category) {
    searchValue = `filename::"${query}"`;
  } else {
    searchValue = query;
  }
  const queryParams = `${encodeURIComponent(searchValue !== undefined ? searchValue : '')}&${CONFIG.QUERY_PARAMS.SORTING_ORDER}=${CONFIG.DEFAULT_SORTERS.KNOWLEDGE}`;
  let typeAheadSearchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}&${CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT}=${autoCorrectMode}`;

  if (query && appliedFilters.length === 0) {
    brand.DEFAULT_SEARCH_PARAMS.BCG_INTERNAL.forEach((param) => {
      typeAheadSearchUrl += `&${param.PARAM}=${param.VALUE}`;
    });
  }
  appliedFilters.map((f) => {
    if (f.QUERY_PARAM) {
      typeAheadSearchUrl += `&${f.QUERY_PARAM}=${encodeURIComponent(f.path)}`;
    }
    return typeAheadSearchUrl;
  });
  if (tabSwitch) {
    searchActions.setSearchScope(CONFIG.SEARCH.SCOPES.BCG_INTERNAL)(store.dispatch);
  }
  history.push({ search: typeAheadSearchUrl, pathname: `${CONFIG.UI_URL.BCG_INTERNAL}`, state: { performBcgInternalSearch: true, doNotReload: tabSwitch } });
  store.dispatch(serpActions.setSelectedIndex(getTabIndex(CONFIG.TYPEAHEAD.BCG_INTERNAL.LABEL)));
}

export function goToLabSERP(typeAheadSuggestion, brand, tabSwitch = false, autoCorrectMode = true) {
  let { query } = labSelectors.getQuery(store.getState());
  const lastSearch = labSelectors.getLastSearch(store.getState());
  const scope = searchSelectors.getSearchScope(store.getState());
  const targetTab = CONFIG.SEARCH.SCOPES.LAB;
  const appliedFilters = getAppliedFiltersArray(targetTab);
  query = tabSwitch ? lastSearch.query.query : query;

  let searchValue;
  if (CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.VALUE === typeAheadSuggestion.category) {
    searchValue = `filename::"${query}"`;
  } else {
    searchValue = query;
  }
  const queryParams = `${encodeURIComponent(searchValue !== undefined ? searchValue : '')}&${CONFIG.QUERY_PARAMS.SORTING_ORDER}=${CONFIG.DEFAULT_SORTERS.KNOWLEDGE}`;
  let typeAheadSearchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}&${CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT}=${autoCorrectMode}`;

  if (query && appliedFilters.length === 0) {
    brand.DEFAULT_SEARCH_PARAMS.LAB.forEach((param) => {
      typeAheadSearchUrl += `&${param.PARAM}=${param.VALUE}`;
    });
    if (typeAheadSuggestion.category === CONFIG.TYPEAHEAD.LAB.CATEGORIES.AUTHOR.VALUE) {
      typeAheadSearchUrl = `${typeAheadSearchUrl}&${CONFIG.QUERY_PARAMS.AUTHOR}=${encodeURIComponent(searchValue)}`;
    }
  }
  appliedFilters.map((f) => {
    if (f.QUERY_PARAM) {
      typeAheadSearchUrl += `&${f.QUERY_PARAM}=${encodeURIComponent(f.path)}`;
    }
    return typeAheadSearchUrl;
  });
  if (tabSwitch) {
    searchActions.setSearchScope(CONFIG.SEARCH.SCOPES.LAB)(store.dispatch);
  }
  history.push({ search: typeAheadSearchUrl, pathname: `${CONFIG.UI_URL.LAB}`, state: { performLabSearch: true, doNotReload: tabSwitch } });
  store.dispatch(serpActions.setSelectedIndex(getTabIndex(CONFIG.TYPEAHEAD.LAB.LABEL)));
}

export function openUserProfile(person) {
  const url = CONFIG.KN_URL.AUTHOR_PROFILE(person.id);
  window.open(`${url.indexOf('http') > -1 ? '' : 'http://'}${url}`,'_blank');
}

export function getFormattedProfileString(profilesObj) {
  let profileString = 'KS'.concat(profilesObj.basicKeywordSearch?.substring(profilesObj.basicKeywordSearch.lastIndexOf('_')));
  profileString += '|LD'.concat(profilesObj.labDetails?.substring(profilesObj.labDetails.lastIndexOf('_')));
  profileString += '|TS'.concat(profilesObj.topicSuggestions?.substring(profilesObj.topicSuggestions.lastIndexOf('_')));
  profileString += '|TA'.concat(profilesObj.caseSearch?.substring(profilesObj.caseSearch.lastIndexOf('_')));
  profileString += '|TA'.concat(profilesObj.caseTypeAhead?.substring(profilesObj.caseTypeAhead.lastIndexOf('_')));
  profileString += '|TA'.concat(profilesObj.knowledgeSearch?.substring(profilesObj.knowledgeSearch.lastIndexOf('_')));
  profileString += '|TA'.concat(profilesObj.knowledgeTypeAhead?.substring(profilesObj.knowledgeTypeAhead.lastIndexOf('_')));
  profileString += '|TA'.concat(profilesObj.labSearch?.substring(profilesObj.labSearch.lastIndexOf('_')));
  profileString += '|TA'.concat(profilesObj.labTypeAhead?.substring(profilesObj.labTypeAhead.lastIndexOf('_')));
  profileString += '|TA'.concat(profilesObj.slideLevelKnowledgeSearch?.substring(profilesObj.slideLevelKnowledgeSearch.lastIndexOf('_')));
  return profileString;
}

export const filterSidePanelItems = (items, query, useStrictRegExp = false) => {
  if (items?.length === 0 || !query) {
    return [];
  }
 
  const queryWithoutQuotation = query.replace(/^['"]*/g, '').replace(/[`"]*$/, '').replace(/[`"]/, ' ');
  if (!useStrictRegExp) {
    return items
      .filter((item) => queryWithoutQuotation.toLowerCase().split(' ').some((word) => word.length > 1 && item.searchKeywords.toLowerCase().indexOf(word) > -1));
  }

  let queryEx = new RegExp(replaceSpecialRegExCharacters(queryWithoutQuotation).replace(/\s/g, '[0-9a-z\\s]*'), 'ig');
  if (queryWithoutQuotation.length <= 3) {
    queryEx = new RegExp(`\\b${replaceSpecialRegExCharacters(queryWithoutQuotation)}\\b`, 'ig');
  }
  return items
    .filter((item) => {
      // Because the regEx has global flag, the RegEx may become having non-zero 'lastIndex' value from last testing, which ruined the next testing!
      queryEx.lastIndex = 0;

      return queryEx.test(item.searchKeywords);
    });
};

export const filterKeywordItemsBy = (items, query) => {
  if (items?.length === 0 || !query) {
    return [];
  }
 
  const queryWithoutQuotation = query.replace(/^['"]*/g, '').replace(/[`"]*$/, '').replace(/[`"]/, ' ');
  let queryEx = new RegExp(replaceSpecialRegExCharacters(queryWithoutQuotation).replace(/\s/g, '[0-9a-z\\s]*'), 'ig');
  if (queryWithoutQuotation.length <= 3) {
    queryEx = new RegExp(`\\b${replaceSpecialRegExCharacters(queryWithoutQuotation)}\\b`, 'ig');
  } 
  return items
    .filter((item) => {
      // Because the regEx has global flag, the RegEx may become having non-zero 'lastIndex' value from last testing, which ruined the next testing!
      queryEx.lastIndex = 0;
  
      const itemTopicUrl = item.topicUrl ? new URL(item.topicUrl) : undefined;
      // Exclude item that has url path is too general, not specific, like having its path === '/'.
      // return queryEx.test(item.searchKeywords) && itemTopi/cUrl && itemTopicUrl.pathname !== '/';
      // Exclude {"topicUrl": "https://practiceareas.navigator.bcg.com"}
      return queryEx.test(item.searchKeywords) && itemTopicUrl && !(itemTopicUrl.hostname.toLowerCase() === 'practiceareas.navigator.bcg.com' && itemTopicUrl.pathname === '/');
    });
};

export function goToPaTopicsSERP(brand, tabSwitch = false, autoCorrectMode = true) {
  let { query } = paTopicsSelectors.getQuery(store.getState());
  const lastSearch = paTopicsSelectors.getLastSearch(store.getState());
  const scope = searchSelectors.getSearchScope(store.getState());
  const targetTab = CONFIG.SEARCH.SCOPES.PA_TOPICS;
  const appliedFilters = getAppliedFiltersArray(targetTab);
  query = tabSwitch ? lastSearch.query.query : query;
  const queryParams = encodeURIComponent(query !== undefined ? query : '');
  let typeAheadSearchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}&${CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT}=${autoCorrectMode}`;

  if (query && appliedFilters.length === 0) {
    brand.DEFAULT_SEARCH_PARAMS.PA_TOPICS.forEach((param) => {
      typeAheadSearchUrl += `&${param.PARAM}=${param.VALUE}`;
    });
  }
  appliedFilters.map((f) => {
    if (f.QUERY_PARAM) {
      typeAheadSearchUrl += `&${f.QUERY_PARAM}=${f.text}`;
    }
    return typeAheadSearchUrl;
  });
  if (tabSwitch) {
    searchActions.setSearchScope(CONFIG.SEARCH.SCOPES.PA_TOPICS)(store.dispatch);
  }
  history.push({ search: typeAheadSearchUrl, pathname: `${CONFIG.UI_URL.PA_TOPICS}`, state: { doNotReload: tabSwitch } });
  store.dispatch(serpActions.setSelectedIndex(getTabIndex(CONFIG.TYPEAHEAD.PA_TOPICS.LABEL)));
}