import React, { Suspense, lazy, useEffect, useState } from 'react';

// in order of appearance in the App component
import { Security, SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import oktaAuth from 'redux/auth';

import { Provider } from 'react-redux';
import store from 'redux/store';
import { ConnectedRouter } from 'connected-react-router';
import history from 'redux/history';
import BrandWrapper from 'components/BrandWrapper/BrandWrapper';
import ResponsiveWrapper from 'components/ResponsiveWrapper/ResponsiveWrapper';
import { ThemeProvider } from 'styled-components';
import defaultTheme from 'global-styles/defaultTheme';
import { BCGHeader } from '@kdshared/global-nav';
import ChatHeader from 'components/ChatHeaderMenu/ChatHeaderMenu';
import LoadingComponent from 'containers/Loading/Loading';
import ProposalBuilder from 'containers/ProposalBuilder/ProposalBuilder';
import ProposalBuilderDetailsContainer from 'containers/ProposalBuilder/ProposalBuilderDetails';
import { Route, Switch, Redirect } from 'react-router-dom';
import ANALYTICS from 'utils/analytics/analytics';
import { isAndroid, isIos } from 'utils/mobile/mobile';

import CONFIG from 'config';

import 'assets/fonts/HendersonBCGSans-Bold.woff2';
import 'assets/fonts/HendersonBCGSans-Regular.woff2';
import 'assets/fonts/HendersonBCGSans-Light.woff2';

const HomeContainer = lazy(() => import(
  /* webpackPreload: true */
  /* webpackChunkName: "chunk-Home" */
  'containers/Home/Home'));
const SERPContainer = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "chunk-SERPContainer" */
  'containers/SERP/SERP'));
const DocVizMaterialContainer = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "chunk-Material" */
  'containers/SERP/Materials/DocVizMaterial'));
const AnalyticsUtil = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "chunk-AnalyticsUtil" */
  'containers/AnalyticsUtil/AnalyticsUtil'));
const ErrorComponent = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "chunk-Error" */
  'components/Error/Error'));
const ChatContainer = lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "chunk-Chat" */
  'containers/Chat/Chat'));

function isIE() {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  // IE 10 or older
  const msie = ua.indexOf('MSIE ');
  // IE 11
  const trident = ua.indexOf('Trident/');
  return msie > 0 || trident > 0;
}

const App = () => {

  const [isChat, setIsChat] = useState(false);

  const appLoad = async () => {
    ANALYTICS.page.initialize(null, 'Page', false);
    if (isIE() && window.location.pathname !== '/error/ie11') window.location.href = '/error/ie11';
    // trigger a virtual pageView to allow usabilla to track new page views in a SPA
    history.prevlocation = null;
    history.listen((next) => {
      history.prevlocation = next;
      try {
        window.usabilla_live('virtualPageView');
      } catch (error) {
        console.error('usabilla', error);
      }

      if (
        next.pathname !== CONFIG.UI_URL.CHAT) {
        setIsChat(false);
      }
      else if (next.pathname === CONFIG.UI_URL.CHAT && !isChat) {
        setIsChat(true);
      }
    });
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const getSelectedHeader = () => {
    if (isChat && (isIos() || isAndroid())) {
      return <ChatHeaderWrapper />;
    }
    else if (window.location.pathname != '/error/ie11') {
      return <BCGHeaderWrapper />;
    }
    else return null;
  };

  useEffect(() => {
    if (window.location.pathname !== CONFIG.UI_URL.CHAT) {
      setIsChat(false);
    }
    else if (window.location.pathname === CONFIG.UI_URL.CHAT && !isChat) {
      setIsChat(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    appLoad();
    const chatbotParam = new URLSearchParams(window.location.search).get('chatbot');
    if (chatbotParam === 'true') {
      window.location.href = `${CONFIG.UI_URL.CHAT}`;
    }
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <BrandWrapper>
            <ResponsiveWrapper>
              <ThemeProvider theme={defaultTheme}>
                {getSelectedHeader()}
                <Suspense fallback={<LoadingComponent />}>
                  <Switch>
                    <SecureRoute exact path={CONFIG.UI_URL.HOME} component={HomeContainer} />
                    <SecureRoute path="/results" component={SERPContainer} />
                    <SecureRoute path="/material" component={DocVizMaterialContainer} />
                    <SecureRoute path={CONFIG.UI_URL.KNOWLEDGE} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.PA_TOPICS} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.CASE} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.PEOPLE} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.MATERIAL} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.LAB} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.BCG_INTERNAL} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.EXPERTS} component={SERPContainer} />
                    <SecureRoute path={CONFIG.UI_URL.CHAT} component={ChatContainer} />
                    <SecureRoute path={`${CONFIG.UI_URL.PROPOSALBUILDER}/details`} component={ProposalBuilderDetailsContainer} />
                    <SecureRoute path={CONFIG.UI_URL.PROPOSALBUILDER} component={ProposalBuilder} />
                    <Route path={CONFIG.OKTA.REDIRECT_URL} component={() => {
                      const oktaParams = new URLSearchParams(window.location.hash?.replace('#', '?'));
                      if (!oktaParams?.get('state') || oktaParams?.get('access_token')?.split('.').length !== 3) {
                        oktaAuth.signInWithRedirect({ originalUri: CONFIG.UI_URL.HOME });
                        return null;
                      }
                      return <LoginCallback errorComponent={ErrorComponent} />;
                    }} />
                    <SecureRoute path={CONFIG.UI_URL.ERROR(':errorCode')} component={ErrorComponent} />
                    <SecureRoute path={CONFIG.UI_URL.ANALYTICS_UTIL} component={AnalyticsUtil} />
                    <Redirect to={CONFIG.UI_URL.ERROR_WITH_SOURCE('404')} />
                  </Switch>
                </Suspense>
              </ThemeProvider>
            </ResponsiveWrapper>
          </BrandWrapper>
        </Security>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;

const BCGHeaderWrapper = () => {
  const { authState } = useOktaAuth();
  if (!authState?.isAuthenticated) return null;
  return <BCGHeader environment={CONFIG.ENVIRONMENT === 'local' ? 'qa' : CONFIG.ENVIRONMENT} apiConfig={CONFIG.SHARED_CONFIG} isUsingSearchAnalytics={false} />;
};

const ChatHeaderWrapper = () => {
  const { authState } = useOktaAuth();
  if (!authState?.isAuthenticated) return null;
  return <ChatHeader />;
};