import CONFIG from 'config';

// basic list of tabs without state so we can use this elsewhere
// without importing the other stuff

// put in order of appearance across the top
// MAKE SURE to update tabList.js
const tabs = [
  {
    brandSearchParam: 'KNOWLEDGE', // used to link to the tabList.js
    label: CONFIG.SEARCH.SCOPES.BUTTONS.MATERIALS.title,
    scope: CONFIG.SEARCH.SCOPES.BUTTONS.MATERIALS.scope,
    filterConfig: CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR,
    sortList: CONFIG.KNOWLEDGE_SORTERS,
    defaultSort: CONFIG.DEFAULT_SORTERS.KNOWLEDGE,
    url: CONFIG.UI_URL.KNOWLEDGE,
    isShow: CONFIG.FEATURE_TOGGLES.SHOW_KNOWLEDGE_TAB,
    typeAhead: CONFIG.TYPEAHEAD.KNOWLEDGE
  },
  {
    brandSearchParam: 'PA_TOPICS',
    label: CONFIG.SEARCH.SCOPES.BUTTONS.PA_TOPICS.title,
    scope: CONFIG.SEARCH.SCOPES.BUTTONS.PA_TOPICS.scope,
    url: CONFIG.UI_URL.PA_TOPICS,
    filterConfig: CONFIG.PA_TOPICS_FILTERS.FILTER_BAR,
    isShow: CONFIG.FEATURE_TOGGLES.SHOW_PA_TOPICS_TAB,
    typeAhead: CONFIG.TYPEAHEAD.PA_TOPICS
  },
  {
    brandSearchParam: 'CASE',
    label: CONFIG.SEARCH.SCOPES.BUTTONS.CASES.title,
    scope: CONFIG.SEARCH.SCOPES.BUTTONS.CASES.scope,
    url: CONFIG.UI_URL.CASE,
    filterConfig: CONFIG.CASE_FILTERS.FILTER_BAR,
    sortList: CONFIG.CASE_SORTERS,
    defaultSort: CONFIG.DEFAULT_SORTERS.CASE,
    isShow: CONFIG.FEATURE_TOGGLES.SHOW_CASE_TAB,
    typeAhead: CONFIG.TYPEAHEAD.CASE
  },
  {
    brandSearchParam: 'PEOPLE',
    label: CONFIG.SEARCH.SCOPES.BUTTONS.DIRECTORY.title,
    scope: CONFIG.SEARCH.SCOPES.BUTTONS.DIRECTORY.scope,
    url: CONFIG.UI_URL.PEOPLE,
    filterConfig: CONFIG.PEOPLE_FILTERS,
    isShow: true,
    typeAhead: CONFIG.TYPEAHEAD.PEOPLE
  },
  {
    brandSearchParam: 'EXPERTS',
    label: CONFIG.SEARCH.SCOPES.BUTTONS.EXPERTS.title,
    scope: CONFIG.SEARCH.SCOPES.BUTTONS.EXPERTS.scope,
    url: CONFIG.UI_URL.EXPERTS,
    filterConfig: CONFIG.EXPERT_FILTERS.FILTER_BAR,
    isShow: CONFIG.FEATURE_TOGGLES.SHOW_EXPERTS_TAB,
    typeAhead: CONFIG.TYPEAHEAD.EXPERTS,
    sortList: CONFIG.EXPERT_SORTERS,
    defaultSort: CONFIG.DEFAULT_SORTERS.EXPERT
  },
  {
    brandSearchParam: 'LAB',
    label: CONFIG.SEARCH.SCOPES.BUTTONS.LAB.title,
    scope: CONFIG.SEARCH.SCOPES.BUTTONS.LAB.scope,
    url: CONFIG.UI_URL.LAB,
    filterConfig: CONFIG.LAB_FILTERS.FILTER_BAR,
    isShow: true,
    typeAhead: CONFIG.TYPEAHEAD.LAB
  },
  {
    brandSearchParam: 'BCG_INTERNAL',
    label: CONFIG.SEARCH.SCOPES.BUTTONS.BCG_INTERNAL.title,
    scope: CONFIG.SEARCH.SCOPES.BUTTONS.BCG_INTERNAL.scope,
    filterConfig: CONFIG.BCG_INTERNAL_FILTERS.FILTER_BAR,
    url: CONFIG.UI_URL.BCG_INTERNAL,
    sortList: CONFIG.KNOWLEDGE_SORTERS,
    defaultSort: CONFIG.DEFAULT_SORTERS.BCG_INTERNAL,
    isShow: CONFIG.FEATURE_TOGGLES.SHOW_BCG_INTERNAL_TAB,
    typeAhead: CONFIG.TYPEAHEAD.BCG_INTERNAL
  }
];

export default tabs.filter((t) => t.isShow);