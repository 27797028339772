import _ from 'lodash';
import CONFIG from 'config';
import { flattenQuery } from 'utils/string-mapper/string-mapper';
import ANALYTICS from 'utils/analytics/analytics';
import { sendToLocalStorage, getFromLocalStorage } from 'utils/localStorage/localStorage';
import { getUserSearchPhrase } from 'utils/advancedsearch/advancedsearch';

export const CUSTOM_DATES = 'Custom Dates';
export const CUSTOM_DATE_VALUE_REGEX = /[0-9]to[0-9]/;
export const DEFAULT_CHECKBOX_TRUE = 'YES';

export const getNameFromAttributeCode = (attributeCode) => {
  if (typeof (attributeCode) !== 'string') {
    console.error(`Invalid arguement of type ${typeof attributeCode} provided. Needed string`);
    return '';
  }
  const attributeCodeArray = attributeCode.split('>');
  const name = attributeCodeArray[attributeCodeArray.length - 1];
  return name;
};

export const fetchFilterValue = (appliedFilters, appliedQueryParams, field) => {
  let ret;
  if (appliedFilters[field]) {
    if (Array.isArray(appliedFilters[field])) {
      ret = appliedFilters[field][0].name;
    } else {
      ret = appliedFilters[field].name;
    }
  } else if (appliedQueryParams[field]) {
    ret = appliedQueryParams[field];
  }
  return ret;
};
export const getFilterInConfig = (CONFIG_SUBSECTION, SUBSECTION, queryParamKey) => Object.values(CONFIG_SUBSECTION[SUBSECTION]).find((config) => config.QUERY_PARAM === queryParamKey);

export const getMonthDisplay = (month) =>
  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month];

export const splitDateRangeString = (dateRangeString) => {
  // check if contains to
  if (!dateRangeString.search('to')) {
    throw new Error('malformed date range string. Form should be M-D-YYYYtoM-D-YYYY');
  }
  const dateStringArray = dateRangeString.split('to');
  if (dateStringArray.length > 2) {
    throw new Error('malformed date range string');
  }


  // - was not supporting in safari so we've used /
  function replaceDashWithForwardSlash(date){
    return date.replace(/\x2D/g, '/');
  }

  const beginDate = new Date(replaceDashWithForwardSlash(dateStringArray[0]));
  const endDate = new Date(replaceDashWithForwardSlash(dateStringArray[1]));

  // add date checks  
  return {
    beginDate,
    beginDateRaw: dateStringArray[0],
    endDate,
    endDateRaw: dateStringArray[1],
  };
};
export const findNestedParentFilter = (options, target) => {
  const parent = options.find((o) => o.field === target);
  if (parent) {
    return parent;
  }
  let result;
  let i = 0;
  while (!result && i < options.length) {
    if (options[i].children && options[i].children.length > 0) {
      result = findNestedParentFilter(options[i].children, target);
    }
    i += 1;
  }
  return result;
};

export const checkFullBranch = (option, currentFilters = {}) => {
  let checkedFilters = {};
  if (option.children && option.children.length > 0) {
    for (let i = 0; i < option.children.length; i += 1) {
      checkedFilters[option.children[i].path] = option.children[i].path;
      if (option.children[i].children && option.children[i].children.length > 0) {
        checkedFilters = { ...checkedFilters, ...checkFullBranch(option.children[i], checkedFilters) };
      }
    }
  }
  checkedFilters[option.path] = option.path;
  return { ...checkedFilters, ...currentFilters };
};

export const handleOnUpdateFilters = (updateFilters, serpActions, dispatch) => {
  const _filters = flattenQuery(updateFilters);
  if (_filters.dateRange) {
    delete _filters.startDateOpened;
    delete _filters.endDateOpened;
  }
  if (_filters.dateRevised) {
    delete _filters.startDateRevised;
    delete _filters.endDateRevised;
    delete _filters.revisedDateRange;
  }
  if (_filters.revisedDateRange) { // delete old filters
    delete _filters.startDateRevised;
    delete _filters.endDateRevised;
  }
  if (_filters.datePublished) {
    delete _filters.startDatePublished;
    delete _filters.endDatePublished;
    delete _filters.publishedDateRange;
  }
  if (_filters.publishedDateRange) { // delete old filters
    delete _filters.startDatePublished;
    delete _filters.endDatePublished;
  }
  if (_filters.dateOpened) {
    delete _filters.startDateOpened;
    delete _filters.endDateOpened;
    delete _filters.openedDateRange;
  }
  if (_filters.openedDateRange) { // delete old filters
    delete _filters.startDateOpened;
    delete _filters.endDateOpened;
  }
  if (_filters.dateClosed) {
    delete _filters.startDateClosed;
    delete _filters.endDateClosed;
    delete _filters.closedDateRange;
  }
  if (_filters.closedDateRange) { // delete old filters
    delete _filters.startDateClosed;
    delete _filters.endDateClosed;
  }
  dispatch(serpActions.setAppliedFilters(_filters));
  // future thoughts: possibly add a flag to igore this popstate removal
  sessionStorage.removeItem(CONFIG.SESSION_STORAGE.IS_POP_STATE);
};

export const handleSortChange = (sortByPreferenceKey, sortFlag, sortingOrder, 
  appliedQueryParams, appliedFilters, currentSERPActions, dispatch, useLocalStorage) => {
  if (useLocalStorage && getFromLocalStorage(sortByPreferenceKey) !== sortingOrder) {
    sendToLocalStorage(sortByPreferenceKey, sortingOrder);
  }
  if (appliedQueryParams[sortFlag] !== sortingOrder) {
    ANALYTICS.displayControl.changeSort(appliedQueryParams[sortFlag], sortingOrder);
    dispatch(currentSERPActions.changeSorter(sortingOrder));
    handleOnUpdateFilters({ ...appliedQueryParams, ...appliedFilters, sortingOrder }, currentSERPActions, dispatch);
  }
};

export const applyUserPreferenceSort = (sortByPreferenceKey, sortFlag, appliedQueryParams, appliedFilters, caseActions, dispatch) => {
  const userSortByPreference = getFromLocalStorage(sortByPreferenceKey);
  if (userSortByPreference) {
    handleSortChange(sortByPreferenceKey, sortFlag, userSortByPreference, appliedQueryParams, appliedFilters, caseActions, dispatch);
  }
};

const handleSetActiveFilter = (activeFilter, currentSERPActions, dispatch) => {
  dispatch(currentSERPActions.setActiveFilter(activeFilter));
  sessionStorage.removeItem(CONFIG.SESSION_STORAGE.IS_POP_STATE);
};

export const removeFilterFromQuery = (toBeRemovedfilters, searchState, currentSERPActions, dispatch, isAdvancedSearch) => {
  const newSearchParams = { ...searchState.appliedFilters, ...searchState.appliedQueryParams };

  const execute = (filterToBeRemoved) => {
    const newFilterValues = newSearchParams[filterToBeRemoved.QUERY_PARAM].filter((a) => a.path !== filterToBeRemoved.path);
    if (newFilterValues && newFilterValues.length > 0) {
      newSearchParams[filterToBeRemoved.QUERY_PARAM] = newFilterValues;
    } else {
      delete newSearchParams[filterToBeRemoved.QUERY_PARAM];
    }
  
    ANALYTICS.refiners.clearFilter(filterToBeRemoved.QUERY_PARAM, filterToBeRemoved.path, isAdvancedSearch);
  };

  if (Array.isArray(toBeRemovedfilters)) {
    toBeRemovedfilters.forEach(f => execute(f));
  } else {
    execute(toBeRemovedfilters);
  }

  handleSetActiveFilter('', currentSERPActions, dispatch);
  handleOnUpdateFilters(newSearchParams, currentSERPActions, dispatch);
};

const parseValueFromPath = (text) => text.replace(/^\/(.*?)\/?$/, '$1');
const normalizePathValue = (text) => `/${text}/`.replaceAll('//', '/');

const setFilterDisplay = (text, filterConfig, queryParamKey, filterBarConfig) => {
  let displayFieldName = '';
  const searchFilterConfig = Object.values(filterBarConfig).find((f) => queryParamKey.includes(f.QUERY_PARAM));

  if (searchFilterConfig.showFieldName) {
    displayFieldName = searchFilterConfig.name;
  }
  if (filterConfig.CHECKBOX_ONLY_FILTER) {
    return displayFieldName ? `${displayFieldName}: ${filterConfig.name}` : filterConfig.name;
  }
  let displayText = '';

  switch (searchFilterConfig.type) {
    case 'daterange':
      const { beginDate, endDate } = splitDateRangeString(text);
      displayText = `${getMonthDisplay(beginDate.getMonth())} ${beginDate.getFullYear()} - ${getMonthDisplay(endDate.getMonth())} ${endDate.getFullYear()}`;
      break;
      
    default:
      if (queryParamKey === filterBarConfig.ADVANCED_SEARCH?.QUERY_PARAM) {
        const advancedSearchFilterConfig = Object.values(filterBarConfig).find((f) => !f.HIDE_PILL && text.includes(f.QUERY_PARAM));
        const [phrase, fieldName] = getUserSearchPhrase(text);
        if (advancedSearchFilterConfig && typeof advancedSearchFilterConfig.DISPLAY_FUNCTION === 'function') {
          displayText = advancedSearchFilterConfig.DISPLAY_FUNCTION(parseValueFromPath(phrase ? phrase : text));
        } else {
          displayText = parseValueFromPath(phrase ? phrase : text);
        }
      } else {
        displayText = parseValueFromPath(text);
      }
      const optionHasDedicatedName = filterConfig?.options?.find((option) => option.name === displayText);
      if (optionHasDedicatedName?.display) {
        displayText = optionHasDedicatedName?.display;
      }
  }

  return displayFieldName ? `${displayFieldName}: ${displayText}` : displayText;
};

export const handleUpdateFilterCheckbox = (item, isChecked, actions, storagePrefix, appliedFilters, appliedQueryParams, dispatch) => {
  const updatedFilters = { ...appliedFilters };
  const checkboxFilterValue = item.CHECKBOX_FILTER_VALUE || DEFAULT_CHECKBOX_TRUE;
  if (isChecked) {
    updatedFilters[item.QUERY_PARAM] = [checkboxFilterValue];
  } else {
    delete updatedFilters[item.QUERY_PARAM];
  }
  if (item.PERSISTENT) {
    sendToLocalStorage(`${storagePrefix}${item.QUERY_PARAM}`, isChecked);
  }
  handleOnUpdateFilters({ ...appliedQueryParams, ...updatedFilters }, actions, dispatch);
  sessionStorage.removeItem(CONFIG.SESSION_STORAGE.IS_POP_STATE);
};

export const setAppliedFilters = (queryParams, filterBarConfig, pending, success, failed) => async (dispatch) => {
  dispatch({ type: pending });
  try {
    const appliedFilters = {};
    const appliedQueryParams = {};
    Object.keys(queryParams).forEach((queryParamKey) => {
      const filterConfig = Object.values(filterBarConfig).find((f) => !f.HIDE_PILL && queryParamKey === f.QUERY_PARAM && queryParamKey !== 'query');
      if (filterConfig) {
        appliedFilters[filterConfig.QUERY_PARAM] = [];
        if (typeof (queryParams[queryParamKey]) === 'string') {
          appliedFilters[filterConfig.QUERY_PARAM].push({
            ...filterConfig,
            filterConfig,
            display: setFilterDisplay(queryParams[queryParamKey], filterConfig, queryParamKey, filterBarConfig),
            path: normalizePathValue(queryParams[queryParamKey]),
            name: parseValueFromPath(queryParams[queryParamKey]),
            field: parseValueFromPath(queryParams[queryParamKey])
          });
        } else {
          queryParams[queryParamKey].forEach((text) => {
            appliedFilters[filterConfig.QUERY_PARAM].push({
              ...filterConfig,
              filterConfig,
              display: setFilterDisplay(text, filterConfig, queryParamKey, filterBarConfig),
              path: normalizePathValue(text),
              name: parseValueFromPath(text),
              field: parseValueFromPath(text)
            });
          });
        }
      } else {
        appliedQueryParams[queryParamKey] = queryParams[queryParamKey];
      }
    });
    const appliedFilterAndQueryParams = { appliedFilters, appliedQueryParams };
    dispatch({ type: success, payload: { appliedFilters, appliedQueryParams, appliedFilterAndQueryParams } });
  } catch (ex) {
    console.error('setAppliedFilters', ex.toString());
    dispatch({ type: failed, payload: { errorMessage: ex.toString() } });
  }
};

export const convertFilterObjectToArray = (filterObject) => {
  const filterArray = _.reduce(
    filterObject,
    (sum, value, idxKey, collection) => {
      return sum.concat(value);
    },
    []
  );

  return filterArray;
};

export const updateChildren = (parent, path, fullTree) =>
  parent.children?.map((child) => {
  // filter.name is necessary due to data that lacks .display
    const newChild = {
      ...child,
      display: `${child.display || child.name} (${child.count})`,
      name: `${child.name || child.display}`,
      checked: false,
      field: child.name || child.display,
      MULTISELECT: true,
      path: `${path}${child.name || child.display}/`,
      parent: parent.name || parent.display,
      hasNextLevelOptions: child.children?.length > 0
    };
    newChild.children = fullTree && child.children?.length > 0 ? updateChildren(newChild, newChild.path, fullTree) : [];

    return newChild;
  });

export const getConfigValuesForRefiner = (sinequaRefinerName, topBarFiltersInConfig) => {
  let result = Object.values(topBarFiltersInConfig).find((tbf) => tbf.SINEQUA_PARAMETER_NAME === sinequaRefinerName || false);
  if (!result) {
    result = topBarFiltersInConfig[sinequaRefinerName.toUpperCase().replace(/\//g, '').replace(/\s/g, '')] || false;
  }
  return result || false;
};

export const augmentNeededRefiners = (apiRefinersResponse, topBarFiltersInConfig) => {
  const newRefiners = apiRefinersResponse.filter((r) => getConfigValuesForRefiner(r.parameterName, topBarFiltersInConfig));
  return newRefiners.map((r) => ({ ...r, ...getConfigValuesForRefiner(r.parameterName, topBarFiltersInConfig) }));
};

export const mapItem = (filterItem, parent, filterConfig) => {
  const attributeValue = getNameFromAttributeCode(filterItem.attributeCode);

  return {
    filter: filterConfig,
    count: +filterItem?.count,
    name: attributeValue,
    MULTISELECT: filterConfig?.MULTISELECT || false,
    hasNextLevelOptions: !!filterItem?.children?.length,
    field: attributeValue,
    path: `/${filterItem.attributeCode?.replace(/>/g, '/') || attributeValue}/`,
    display: `${attributeValue} (${filterItem.count})`,
    parent,
    children: filterItem?.children ? 
      filterItem.children.map(i => mapItem(i, attributeValue, filterConfig)) :
      []
  };
};

export const formatFilterOptions = (filter, isAiView) => {
  filter.refinerValues = filter.refinerValues || [];
  const optionsList = filter.refinerValues
    .filter((refinerValue) => refinerValue.count > 0)
    .map((refinerValue) => {
      // TODO: normalize these fields
      const _option = { ...refinerValue };
      const _count = _option.count;
      const { children, options, ...filterWithoutChildren } = filter;
      const _name = _option.display;
      const _display = (typeof filter.DISPLAY_FUNCTION === 'function') ?
        filter.DISPLAY_FUNCTION(_option.display) : _option.display;

      const optionHasMatchedDisplay = options?.find((option) => option.display === _option.display);
      if (optionHasMatchedDisplay?.name) {
        _option.name = optionHasMatchedDisplay.name;
      } else {
        _option.name = _display;
      }

      if (isAiView) {
        _option.display = _display;
      }
      else {
        _option.display = filter.MULTISELECT ? `${_display} (${_count})` : _display;
      }

      _option.checked = false;
      _option.field = encodeURIComponent(optionHasMatchedDisplay ? _option.name : _name);
      _option.hasNextLevelOptions = refinerValue.children?.length > 0;
      _option.path = `/${optionHasMatchedDisplay ? _option.name : _name}/`;
      _option.filter = { ...filter, ...filterWithoutChildren };
      _option.MULTISELECT = filter.MULTISELECT;
      _option.children = updateChildren(_option, _option.path, filter.FULL_TREE);

      return _option;
    });

  // if there's a sort function for the options, apply it
  switch (typeof filter.SORT_BY) {
    case 'string':
      optionsList.sort((a, b) => (a[filter.SORT_BY] > b[filter.SORT_BY] ? 1 : -1));
      break;
    case 'function':
      optionsList.sort(filter.SORT_BY);
      break;
    default:
      break;
  }

  if (filter.DATE_RANGE) {
    const dateRangeOption = mapItem({
      attributeCode: CUSTOM_DATES,
      children: [],
      count: 0
    }, null, filter);
    dateRangeOption.display = dateRangeOption.name;
    optionsList.push(dateRangeOption);
  }

  return optionsList;
};

export const filterSorter = (a, b) => {
  // SORT_ORDER isn't always defined, leading to unexpected sort orders.
  if(!a.SORT_ORDER || !b.SORT_ORDER)
    return 0;

  return a.SORT_ORDER > b.SORT_ORDER ? 1 : -1;
};

//reconstruct tree structure that appears in older enrich APIs and not the current genai-enbl-retrieval service
export const reconstructTreeStructure = (aggregations) => {
  const isPath = (key) => key.includes('/');
  const constructChildRefiners = (refinerValues, refinersTree) => {
    if(!refinersTree){
      refinersTree = [];
    }
    refinerValues.forEach((refiner) => {
      const display = refiner.key.split('/')[0];
      const childPath = refiner.key.split('/').slice(1).join('/');
      const currentRefiner = refinersTree.find((refiner) => refiner.display == display);
      if (!currentRefiner) {
        refinersTree.push({
          display,
          count: refiner.doc_count,
          children: childPath ? constructChildRefiners([{key: childPath, doc_count: refiner.doc_count}], currentRefiner?.children) : null
        });
      } else if (childPath) {
        currentRefiner.children = constructChildRefiners([{key: childPath, doc_count: refiner.doc_count}], currentRefiner?.children);
      }else {
        currentRefiner.count = refiner.doc_count;
      }
    });
    return refinersTree;
  };

  const refiners = Object.entries(aggregations).map(([elasticName, aggregation]) => {
    const filterConfigValue = Object.values(CONFIG.CASE_FILTERS.FILTER_BAR).find((filter) => filter.ELASTIC_PARAMETER_NAME == elasticName.trimEnd());
    return {
      parameterName: filterConfigValue?.SINEQUA_PARAMETER_NAME,
      refinerType: aggregation.buckets.some((refiner) => isPath(refiner.key)) ? 'Tree' : 'List',
      refinerValues: aggregation.buckets.length > 0 ? constructChildRefiners(aggregation.buckets) : []
    };
  });

  console.log(refiners, 'hii');
  return refiners;
};

// formatting for serp reducers
export const formatFilters = (refiners, filterConfig, isAiView = false) => {
  const filtersFromAPI = refiners ? augmentNeededRefiners(refiners, filterConfig)
    .map((f) => ({
      ...f,
      field: f.QUERY_PARAM,
      options: formatFilterOptions(f, isAiView)
    }))
    .sort(filterSorter) : [];
  const filtersFromAPIAndConfig = Object.values(filterConfig)
    .map((f) => {
      const result = filtersFromAPI.find((filter) => filter.QUERY_PARAM === f.QUERY_PARAM);
      return result || { ...f, options: [] };
    });

  return filtersFromAPIAndConfig;
};

//Some filters returned from the people API include special characters that must be escaped with filtered with
//e.g. Asia Pacific (ALL)
export const escapeGraphQLSpecialCharacters = (str) => {
  const encodedStr = encodeURIComponent(str)
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29');
  
  return decodeURIComponent(encodedStr);
};